import React, { useState } from 'react'
import "./NDVehicleInfoFilterControlsSelectDark.css";
import './NDVehicleInfoFilterControls.css'

const NDVehicleInfoFilterControlsSelectDark = (props) => {
    const [isActive, setIsActive] = useState(false);
    
    const [value, setValue] = React.useState("");
    const handleSelectChange = (v) => {
        switch (v) {
            case "TODAY": setValue("Today")
            break
            case "YESTERDAY": setValue("Yesterday")
            break
            case "LAST_7_DAYS": setValue("Last 7 Days")
            break
            default:
                setValue("Today")
                break
        } 
        props.onFilterChange(getDateRangeByIndex(v))

    }
    const getDateRangeByIndex = (v) => {
        const today = new Date()
        const reqobject = {
            registrationNumber: props.vehicleNo,
            startDateTime: null,
            endDateTime: null,
        }
        switch (v) {
            case "TODAY": // Today
                reqobject.endDateTime = new Date(today.setHours(today.getHours(), today.getMinutes() - 0, 0, 0))
                reqobject.startDateTime = new Date(today.setHours(0, 0, 0, 0))
                break
            case "YESTERDAY": // Yesterday
                const yesterday = new Date(today)
                yesterday.setDate(today.getDate() - 1)
                reqobject.startDateTime = new Date(yesterday.setHours(0, 0, 0, 0))
                reqobject.endDateTime = new Date(yesterday.setHours(23, 59, 59, 999))
                break
            case "LAST_7_DAYS": // Last 7 days
                const last7Days = new Date(today)
                last7Days.setDate(today.getDate() - 7)
                const yesterdayy = new Date(today)
                yesterdayy.setDate(today.getDate() - 1)
                reqobject.startDateTime = new Date(last7Days.setHours(0, 0, 0, 0))
                reqobject.endDateTime = new Date(yesterdayy.setHours(23, 59, 59, 999))
                break
            default:
                console.log('dafault filter index not found')
                break
        }
        return reqobject
    }

    React.useEffect(() => {
        handleSelectChange("TODAY")
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props.vehicleNo])
    return (
        <>
            <div className="nd__filter-select dropdown">
                <div
                    onClick={(e) => {
                        setIsActive(!isActive);
                    }}
                    className="dropdown-btn"
                >
                    {value}
                    <img src='/images/darkLayout/downArrow.svg' alt='down arrow' style={{height:'5px'}}/>
                </div>
                <div
                    className="dropdown-content"
                    style={{ display: isActive ? "block" : "none" }}
                >
                    <div
                        onClick={() => {
                            handleSelectChange("TODAY");
                            setIsActive(!isActive);
                        }}
                        className="item"
                    >
                        Today
                    </div>
                    <div
                        className="item"
                        onClick={(e) => {
                            handleSelectChange("YESTERDAY");
                            setIsActive(!isActive);
                        }}
                    >
                        Yesterday
                    </div>
                    <div
                        className="item"
                        onClick={() => {
                            handleSelectChange("LAST_7_DAYS");
                            setIsActive(!isActive);
                        }}
                    >
                        Last 7 Days
                    </div>
                </div>
            </div>
        </>
    )
}

export default NDVehicleInfoFilterControlsSelectDark;