import React, { Component } from 'react'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';

import NDTrackingChart from './nd-trackingChart/NDTrackingChart'
import NDTrackingLegendsCard from './nd-trackingLegendsCard/NDTrackingLegendsCard'
import NDtrackingAlertCard from './nd-trackingAlertCard/NDtrackingAlertCard'
// import NDTrackingWebCam from './nd-trackingWebCam/NDTrackingWebCam'
// import NDVehicleInfoHeader from './nd-vehicleInfo-header/NDVehicleInfoHeader'
// import NDVehicleInfoCanStatus from './nd-vehicleInfo-canStatus/NDVehicleInfoCanStatus'
import NDVehicleInfoToggleTabs from './nd-vehicleInfo-toggle-tabs/NDVehicleInfoToggleTabs'
import NDVehicleInfoCharging from './nd-vehicleInfo-charging/NDVehicleInfoCharging'
import {
  getVehicleTrackingChargingDetails,
  getVehicleTrackingCanData,
  getVehicleTrackingChargingDetailsAD,
  getVehicleTrackingCanDataDetailsAD
} from '../../../../store/actions/vehicle/vehicle'
import dashboardAction from '../../../../store/actions/dashboard/dashboard'
import { GetAddressByCoordinates } from '../../../../store/actions/map/latlng-to-address';
import NDVehicleInfoAlarmReport from './nd-vehicleInfo-alarm-report/NDVehicleInfoAlarmReport';

import './NDVehicleInfo.css'
import { errorToast } from '../../../../utils/toasts';
import moment from 'moment'
import { withRouter } from '../../../../service/router'
import { getAllAlarmReset } from '../../../../store/actions/reports/alarm';
import NDVehicleInfoFilterControlsSelectDark from './nd-vehicleInfo-filter-controls/NDVehicleInfoFilterControlsSelectDark';
import { BsX } from "react-icons/bs";

class NDVehicleInfo extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isTrackingTabActive: true,
      isChargingTabActive: false,
      address: '',
      selectedChartDataObj: {},
      showAlertAlarmaAnalyticsModal: false,
      selectedAlarmData: '',
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.makeDetailsScrollable)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.vehicleInfo?.lt !== this.props.vehicleInfo?.lt || prevProps.vehicleInfo?.lng !== this.props.vehicleInfo?.lng) {
      this.setState({ address: '' });
    }
    this.makeDetailsScrollable();
  }

  makeDetailsScrollable = () => {
    const targetHeightsToReduce = ['.nd__vehicle-info-container-header', '.nd__vehicle-info-container-status', '.nd__vehicle-info-tabs-header']
    if (this.props.moduleName === "GROUP") {
      targetHeightsToReduce.push('.gd__sidebar-header')
    } else {
      targetHeightsToReduce.push('.nd__sidebar-header')
    }
    let targetHeight = 0;
    targetHeightsToReduce.forEach(t => {
      targetHeight += document.querySelector(t)?.offsetHeight;
    })
    let detailsParentHeight = 0
    if (this.props.moduleName === "GROUP") {
      detailsParentHeight = document.querySelector('.gd__sidebar')?.offsetHeight;
    } else {
      detailsParentHeight = document.querySelector('.nd__sidebar')?.offsetHeight;
    }
    document.querySelector('.nd__vehicle-info-tabs-content').style.height = `${detailsParentHeight - targetHeight - 70}px`;
  }

  getAddress = async () => {
    const { vehicleInfo } = this.props;
    try {
      const res = await GetAddressByCoordinates({ lat: vehicleInfo.lt, lng: vehicleInfo.lng })
      if (res.status === 200 && res.data.data) {
        this.setState({ address: res.data.data.address });
      } else {
        res.data.error.forEach(error => {
          errorToast({ message: error.message });
        });
      }
    } catch (error) {
      console.log(error)
    }
  }

  setActiveTabContentHandler = (tab) => {
    this.setState({
      isTrackingTabActive: tab === 'TRACKING_VIEW',
      isChargingTabActive: tab === 'CHARGING_VIEW',
    })
  }

  getTrackingChargingDetails = async (reqObject) => {
    this.setState({ selectedChartDataObj: reqObject })
    try {
      const response = await getVehicleTrackingChargingDetails(reqObject)
      if (response.status === 200 && response.data.data) {
        this.props.getTrackingChargingDetails(response.data.data)
        this.props.setVehicleTrackingChargingData(response.data.data)
      }
    } catch (error) {
      console.log(error)
    } finally {
    }
  }

  getTrackingCanDataDetails = async (reqObject) => {
    try {
      this.props.getTrackingCanDataDetails({})
      this.props.setVehicleTrackingCanData({})
      const response = await getVehicleTrackingCanData(reqObject)
      if (response.status === 200 && response.data.data) {
        this.props.getTrackingCanDataDetails(response.data.data)
        this.props.setVehicleTrackingCanData(response.data.data)
      }
    } catch (error) {
      console.log(error)
    } finally {
    }
  }

  onHeaderClose = () => {
    this.props.onCloseSidebar()
  }

  handleAlertClick = (alert, label) => {
    this.setState({ showAlertAlarmaAnalyticsModal: true, selectedAlarmData: alert, selectedAlarmLabel: label })
  }

  closeAlertAlarmaAnalyticsModal = () => {
    this.props.resetAlertReportData()
    this.setState({ showAlertAlarmaAnalyticsModal: false, selectedAlarmData: '', selectedAlarmLabel: '' })
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.makeDetailsScrollable)
    this.props.resetTrackingDetails()
    this.props.resetAlertReportData()
    this.setState({
      isTrackingTabActive: true,
      isChargingTabActive: false,
      address: '',
      selectedChartDataObj: {},
      showAlertAlarmaAnalyticsModal: false,
      selectedAlarmData: '',
    })
  }

  prepareRouteData = () => {
    let tempVehicleChartData = [];
    const startDateTime = moment(this.state.selectedChartDataObj?.startDateTime);
    const endDateTime = moment(this.state.selectedChartDataObj?.endDateTime);
    const currentTime = moment(); // Current time
  
    if (this.props?.vehicleChartData?.routeData && this.props?.vehicleChartData?.routeData?.length) {
      // Deep copy the route data to avoid mutating the original data
      tempVehicleChartData = JSON.parse(JSON.stringify(this.props?.vehicleChartData?.routeData));
      
      let result = [];
      let currentTimestamp = startDateTime.clone();
      let i = 0;
      
      // Determine the end timestamp for the loop (whichever is earlier: endDateTime or currentTime)
      const loopEndTime = moment.min(endDateTime, currentTime);
      
      // Process existing data points and fill gaps with mock data where necessary
      while (currentTimestamp.isBefore(loopEndTime)) {
        // Check if the current timestamp matches the next actual data point
        if (i < tempVehicleChartData.length && currentTimestamp.isSame(moment(tempVehicleChartData[i].time))) {
          // Add actual data point if it matches the current timestamp
          result.push(tempVehicleChartData[i]);
          i++;
        } else if (i < tempVehicleChartData.length && currentTimestamp.isBefore(moment(tempVehicleChartData[i].time))) {
          // Add missing data points between actual data points
          result.push(this.createEmptyDataPoint(currentTimestamp));
        } else if (i >= tempVehicleChartData.length) {
          // Add missing data points after the last actual data point
          result.push(this.createEmptyDataPoint(currentTimestamp));
        }
        currentTimestamp.add(5, 'minutes');
      }
      
      // Add remaining actual data points after loop end time
      while (i < tempVehicleChartData.length) {
        result.push(tempVehicleChartData[i]);
        i++;
      }
  
      // Final result after processing
      tempVehicleChartData = result;
    } else {
      // Generate data points from startDateTime to endDateTime if no route data is present
      let currentTimestamp = startDateTime.clone();
      const loopEndTime = moment.min(endDateTime, currentTime);
      
      // Generate mock data points at 5-minute intervals
      while (currentTimestamp.isBefore(loopEndTime)) {
        tempVehicleChartData.push(this.createEmptyDataPoint(currentTimestamp));
        currentTimestamp.add(5, 'minutes');
      }
  
      // Add final point if loop ends exactly at current time
      if (currentTimestamp.isSame(currentTime, 'minute')) {
        tempVehicleChartData.push(this.createEmptyDataPoint(currentTimestamp));
      }
    }
  
    return tempVehicleChartData;
  };
  
  // Helper function to create an empty data point
  createEmptyDataPoint = (timestamp) => {
    return {
      ig: null,
      soc: null,
      time: timestamp.toISOString(),
      vs: null,
      nct: 'non-communication',
    };
  }; 
  
  render() {
    const { isTrackingTabActive, isChargingTabActive, showAlertAlarmaAnalyticsModal, selectedChartDataObj, selectedAlarmData, selectedAlarmLabel } = this.state
    const {
      selectedAlert,
      // dashboardCamUrl,
      vehicleInfo,
      vehicleChartData,
      vehicleTrackingCanData
      // isFavourite,
      // onFavoriteFlagClick
    } = this.props

    // Prepare Route Data for vehicles
    const routeData = this.prepareRouteData();

    const handleClose = () => {
      this.closeAlertAlarmaAnalyticsModal()
    }

    const CustomCloseButton = ({ onClick }) => {
      return (
        <div className="popup-close vehicle-info-popup-close" onClick={onClick}>
          <BsX size={24} />
        </div>
      );
    };

    return (
      <div className='nd_vehicle-info-container nd_Dark'>
        {/* <div className='nd__vehicle-info-container-header'>
          <NDVehicleInfoHeader
            vehicleStatus={vehicleInfo?.canStatus?.vs}
            vehicleNo={vehicleInfo?.rn}
            chassisNo={vehicleInfo?.cn}
            motorNo={vehicleInfo?.mn}
            nonCommunication={vehicleInfo?.ncom}
            oem={vehicleInfo?.canStatus?.oem}
            model={vehicleInfo?.canStatus?.mdl}
            address={address}
            getAddress={this.getAddress}
            isFavorite={isFavourite}
            lat={vehicleInfo?.lt}
            long={vehicleInfo?.lng}
            onClose={this.onHeaderClose}
            onFavoriteFlagClick={() => onFavoriteFlagClick(vehicleInfo?.rn, isFavourite)}
          />
        </div> */}

        {/* <div className='nd__vehicle-info-container-status'>
          <NDVehicleInfoCanStatus
            gpsSyncTime={vehicleInfo?.ltime ? moment(vehicleInfo?.ltime).format('DD-MMM-YYYY, HH:mm') : 'NA'}
            canSyncTime={vehicleInfo?.canStatus?.ctime ? moment(vehicleInfo?.canStatus?.ctime).format('DD-MMM-YYYY, HH:mm') : 'NA'}
            batteryVoltage={vehicleInfo?.canStatus?.vbv}
            soc={vehicleInfo?.canStatus?.soc}
            batteryTemperature={vehicleInfo?.canStatus?.vbt}
            distanceTillEmpty={vehicleInfo?.canStatus?.dte}
            nonCommunication={vehicleInfo?.ncom}
          />
        </div> */}

        <div className='nd__dark-vehicle-info-container-tabs'>
          <div className='nd__vehicle-info-tabs-main'>
            <div className='nd__vehicle-info-tabs-header'>
              <div className='nd__vehicle-info-tabs-header-container'>
                <NDVehicleInfoToggleTabs
                  setActiveTabContent={this.setActiveTabContentHandler}
                  isTrackingTabActive={isTrackingTabActive}
                  isChargingTabActive={isChargingTabActive}
                  vehicleTrackingCanData={vehicleTrackingCanData}
                />

                <NDVehicleInfoFilterControlsSelectDark
                  endDateTime={vehicleInfo?.ltime ? vehicleInfo?.ltime : vehicleInfo?.canStatus?.ctime}
                  vehicleNo={vehicleInfo?.rn}
                  onFilterChange={(reqObject) => { this.getTrackingChargingDetails(reqObject);this.getTrackingCanDataDetails(reqObject) } }
                />
              </div>
            </div>

            <div className='nd__vehicle-info-tabs-content'>
              <div
                className='nd__vehicle-tabs'
                data-testid='vehicle-info-tracking-tab-content'
                style={{
                  display: isTrackingTabActive ? 'block' : 'none',
                  backgroundImage: 'url(/images/svgicon/glove_mapBg.png)',
                  // backgroundImage: 'url(/images/svgicon/world_map.svg)' 
                }}
              >
                <NDTrackingChart
                  vehicleStatusData={routeData}
                />

                <NDTrackingLegendsCard />
                {/* <NDTrackingWebCam camURL={dashboardCamUrl} /> */}
              </div>
              <NDtrackingAlertCard
                data={vehicleChartData?.alarmData ? vehicleChartData?.alarmData : []}
                selectedAlert={selectedAlert}
                onActiveAlertChange={this.handleAlertClick}
              />

              <div className='nd_chargingBtn_dark'> <img src='/images/svgicon/trackingScreen/chargingBtn.svg' alt='chargingBtn' /></div>
              <div data-testid='vehicle-info-charging-tab-content' className='nd__vehicle-tabs' >

                <NDVehicleInfoCharging
                  data={routeData ? routeData : []}
                />

                {/* <NDTrackingWebCam camURL={dashboardCamUrl} /> */}
              </div>
            </div>
          </div>
        </div>

        {
          showAlertAlarmaAnalyticsModal ? (
            <Modal
              onHide={this.closeAlertAlarmaAnalyticsModal}
              show={showAlertAlarmaAnalyticsModal}
              backdrop="static"
              keyboard={false}
              centered
              size="xl"
              className='nd_modal_alert'
            >
              <Modal.Header closeButton={false}>
              <CustomCloseButton 
              onClick={handleClose}
               />
              </Modal.Header>
              <Modal.Body className='nd__vehicle-info-alert-modal'>
                <NDVehicleInfoAlarmReport
                  alarmReportDate={{ fromDate: selectedChartDataObj.startDateTime, toDate: selectedChartDataObj.endDateTime }}
                  vehicleNumber={selectedChartDataObj.registrationNumber}
                  selectedAlarmData={selectedAlarmData}
                  selectedAlarmLabel={selectedAlarmLabel}
                  closeAlertAlarmaAnalyticsModal={this.closeAlertAlarmaAnalyticsModal}
                />
              </Modal.Body>
            </Modal>
          ) : null
        }
      </div>
    )
  }
}
NDVehicleInfo.propTypes = {
  setVehicleTrackingChargingData: PropTypes.func.isRequired,
  setVehicleTrackingCanData: PropTypes.func.isRequired,
  resetAlertReportData: PropTypes.func.isRequired,
  resetTrackingDetails: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
  vehicleChartData: state.dashboardReducer.vehicleChartData,
  vehicleTrackingCanData: state.dashboardReducer.vehicleTrackingCanData
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getTrackingChargingDetails: dashboardAction.getTrackingChargingDetails,
      getTrackingCanDataDetails: dashboardAction.getTrackingCanDataDetails,
      setVehicleTrackingChargingData: getVehicleTrackingChargingDetailsAD,
      setVehicleTrackingCanData: getVehicleTrackingCanDataDetailsAD,
      resetAlertReportData: getAllAlarmReset,
      resetTrackingDetails: dashboardAction.resetTrackingDetails,
    },
    dispatch
  )
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(NDVehicleInfo))
